.App {  
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(-360deg);}
}

.top-section {
  height: 50px;
  width: 100%;

  border-bottom: 1px solid;
  display: flex;
  margin: auto;
}
.top-left-section {  
  display: flex;
}
.logo {
  margin: auto;
  margin-left: 40px;
  margin-right: 0px;
  color: #3AC678;
  font-size: 30px;
  font-weight: bold;
}
.header-text {
  margin: auto;
  margin-left: 15px;
  font-weight: bold;
}

.info-div {
  padding-top: 50px;

  width: 70%;
  margin: auto;
}
.about-text-header {
  font-size: 30px;
  display: inline;
  color: #545454;
  border-bottom: 3px solid #3AC678; 
}
.about-main-div {
  padding-top: 0px;
  display: flex;
}
.about-text {
  font-size: 20px;
  margin: auto;
  color: #545454;
  position: relative;
  left: -100px;
  padding-bottom: 20px;
}
.about-text-div {
  margin: auto;
}
.socials-text {
  display: inline;
  
  margin: auto;
  position: relative;
  left: -100px;
  
  font-size: 20px;
  color: #545454;
  border-bottom: 2px solid #3AC678;
}
.socials {
  padding-top: 20px;
  position: relative;
  left: -100px;
}
.socials-link {
  margin-left: 10px;
}
.socials-logo {
  width: 35px;
  height: 35px;
}
.greenlines-img {
  width: 350px;
  height: 350px;
  position: relative;
  left: -100px;
}

.contact-div {
  padding-top: 50px;

  width: 70%;
  margin: auto;
}
.contact-text-header {
  font-size: 30px;
  display: inline;
  color: #545454;
  border-bottom: 3px solid #3AC678;
}
.contact-text-div {
  padding-top: 20px;
  display: flex;
}
.contact-about-text {
  font-size: 20px;
  margin: auto;
  color: #545454;
  padding-right: 20px;
  border-right: 1px solid black;
}
.contact-info-div {
  font-size: 20px;
  padding-left: 20px;
  margin: auto;
  color: #545454;
}
.email-header {
  display: inline;
  border-bottom: 2px solid #3AC678;
}

.projects-div {
  padding-top: 90px;
  margin: auto;
  width: 70%;
  text-align: center;
}
.projects-text-header {
  font-size: 30px;
  display: inline;
  color: #545454;
  border-bottom: 3px solid #3AC678;
}
.projects-about-text {
  padding-top: 0px;
  margin-top: 0px;
  font-size: 25px;
  color: #545454;
  margin-bottom: 90px;
}

@media (max-width: 800px) {
  .top-section{
    justify-content: center;
  }
  .info-div {
    width: 80%;
  }
  .about-main-div {
    display: block;
    text-align: center;
    margin: auto;
  }
  .about-text {
    position: relative;
    left: 0px;
  }
  .greenlines-img {
    position: relative;
    left: 0px;
  }
  .socials-text {
    position: relative;
    left: 0px;
  }
  .socials {
    position: relative;
    left: 0px;
  }

  .contact-div {
    text-align: center;
    width: 70%;
  }
  .contact-text-div {
    display: block;
  }
  .contact-about-text {
    border-right: 0px;
    padding-right: 0px;
    padding-bottom: 25px;
  }
  .contact-info-div {
    padding: 0px;
  }
}